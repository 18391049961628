import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ALink from "../../common/Alink";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "30px",
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "230px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
      paddingTop: "40px",
      width: "85%",
      margin: "0 auto",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
  },
  listContent: {
    paddingBottom: "20px",
    marginBottom: "40px",
  },
  title: {
    fontWeight: "bold",
    color: "#005CB2",
    textAlign: "center",
    padding: "2rem 2rem 0.5rem",
    fontSize: "25px",
    "@media screen and (min-width: 768px)": {
      fontSize: "30px",
    },
    "@media screen and (min-width: 1024px)": {
      fontSize: "35px",
    },
  },
  lessonLink: {
    margin: "0 0.5rem",
    backgroundColor: "transparent",
    width: "100%",
    textDecoration: "none",
    transition: "all 0.35s",
    "&:hover, &:focus": {
      textDecoration: "none",
      backgroundColor: "#F5F5F6",
      fontWeight: "600",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "1.5rem",
    },
    paddingLeft: "0.2rem",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2rem",
    },
  },
  lessonName: {
    color: "#1E88E5",
    fontWeight: "inherit",
  },
}));

function normalize(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .split(" ")
    .join("-");
}

function LessonList({ data }) {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Paper elevation={3} className={classes.listContent}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          className={classes.title}
        >
          Danh sách bài học
        </Typography>
        <List>
          {data.map((lesson, index) => {
            return (
              <ListItem key={index}>
                <ALink
                  to={`/courses/${lesson.courseId}/lessons/${normalize(
                    lesson.id
                  )}`}
                  className={classes.lessonLink}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="h5"
                        component="h3"
                        className={classes.lessonName}
                      >
                        {lesson.name}
                      </Typography>
                    }
                  />
                </ALink>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Container>
  );
}

export default LessonList;
