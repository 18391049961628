import React from "react"
import { Link } from "gatsby"
import Alink from "./Alink"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: "none",
    textTransform: "capitalize",
    margin: theme.spacing(1.5, 0, 1.5, 0),
    padding: theme.spacing(0, 1, 0, 1),
    display: "inline-block",
    textAlign: "center",
    borderRadius: " 20px",
    backgroundColor: "#ccc",
    color: "#000",
    transition: "all .3s linear",
    "&:hover": {
      backgroundColor: "#1E88E5",
      color: "white",
    },
  },
  button: {},
}))

const CustomBreadcrumb = ({ crumbs }) => {
  const classes = useStyles()
  return (
    <Container>
      {crumbs.map(crumb => {
        return (
          <>
            <Link
              className={classes.root}
              to={crumb.pathname}
              style={{ ...crumb.crumbStyle }}
              activeStyle={{ ...crumb.crumbActiveStyle }}
            >
              {crumb.crumbLabel}
            </Link>
            {crumb.crumbSeparator}
          </>
        )
      })}
    </Container>
  )
}

export default CustomBreadcrumb
